import { useTranslation } from 'react-i18next';

import ButtonContainer from './ButtonContainer';
import ButtonLabel from './ButtonLabel';
import IconContainer from './IconContainer';
import { IconSurprise } from '../../../components/Icons/IconSurprise';

const Airdrop = ({ active = false }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <IconSurprise />
      </IconContainer>
      <ButtonLabel active={active} marginTop={11}>
        {t('menu.airdrop')}
      </ButtonLabel>
    </ButtonContainer>
  );
};

export default Airdrop;

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Way from './Buttons/Way';
import Boosts from './Buttons/Boosts';
import Missions from './Buttons/Missions';
import Friends from './Buttons/Friends';
import Debug from '../Debug';
import { useApp } from '../../contexts/AppContext';
import Diary from './Buttons/Diary';
import Airdrop from './Buttons/Airdrop';

const MenuContainer = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: space-between;

  position: absolute;
  bottom: calc(40px * var(--scale));

  left: 50%;
  margin: 0 auto 0 -480px;
  border-radius: 90px;
  width: 935px;
  height: 200px;
  padding: 0 10px;

  transform-origin: center 100%;
  transform: scale(var(--scale));
`;

const MenuMain = () => {
  const { farmingState } = useApp();

  const boostUrl = farmingState === 'stopped' ? '/' : '/boosts';

  return (
    <MenuContainer>
      <NavLink to="/">{({ isActive }) => <Way active={isActive} />}</NavLink>
      <NavLink to={boostUrl}>{({ isActive }) => <Boosts active={isActive || farmingState === 'stopped'} />}</NavLink>
      <NavLink to="/tasks">{({ isActive }) => <Missions active={isActive} />}</NavLink>
      <NavLink to="/friends">
        {({ isActive }) => (
          <Debug>
            <Friends active={isActive} />
          </Debug>
        )}
      </NavLink>
      <NavLink to="/diary">{({ isActive }) => <Diary active={isActive} />}</NavLink>
      <NavLink to="/airdrop">{({ isActive }) => <Airdrop active={isActive} />}</NavLink>
    </MenuContainer>
  );
};

export default MenuMain;

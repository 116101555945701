import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import WebApp from '@twa-dev/sdk';
import moment from 'moment';

import i18n from '../lib/i18n';
import {
  doHapticFeedback,
  exponentialDelay,
  getInvitedBy,
  metrikaEventAppCrashed,
  metrikaEventAppStarted,
  sleep,
} from '../lib/utils';
import { Event } from '../lib/events';
import { getUserTasks, postStart, postUserClaim, postUserTurbo, postUserUpgrade } from '../lib/api';
import Calculator from '../lib/farming/calc';
import { Friend, IApp, IClaimReward, ITask, User } from '../lib/types';
import { getLevelForBalance, getPriceForLevel } from '../lib/leaderboard';
import { notificationEmit } from '../controllers/NotificationsController';
import { getRoundFinishAt, getTaskLevel } from '../lib/farming/util';
import { useTranslation } from 'react-i18next';

export type FarmingState = 'stopped' | 'started' | 'claimed' | 'turbo';
export type UserUpgradeType = 'speed' | 'fuel' | 'luck' | 'turbo';

type AppContextInterface = {
  isBusy: boolean;
  loading: boolean;
  event: Event;
  app: IApp;
  user: User;
  level: number;
  progress: number;
  claimReward: IClaimReward | null;
  farmingState: FarmingState;
  calculator: Calculator;

  tasks: ITask[];
  taskFactor: number;

  friendRewardBuffer: number;
  lastAppUpdate: number;
  friends: Friend[];
  friendsCount: number;

  updateUserTasks: () => Promise<ITask[]>;

  farmingClaim: () => void;
  farmingStart: () => void;
  farmingTurboActivate: () => void;
  userUpgrade: (type: UserUpgradeType) => void;

  clearEvent: () => void;
  updateApp: () => Promise<IApp>;
  setEvent: Dispatch<SetStateAction<Event>>;
};

const initialAppState = { state: { balance: 0 } } as IApp;

const AppContext = createContext<AppContextInterface>({
  isBusy: false,
  loading: false,
  event: null,
  user: {} as User,
  app: initialAppState,
  level: 1,
  progress: 0,
  claimReward: null,
  farmingState: 'stopped',
  calculator: {} as Calculator,
  friendRewardBuffer: 1,

  tasks: [],
  taskFactor: 1,

  lastAppUpdate: 0,
  friends: [],
  friendsCount: 0,

  updateUserTasks: async () => Promise.resolve([]),

  farmingClaim: () => null,
  farmingStart: () => null,
  farmingTurboActivate: () => null,
  userUpgrade: () => null,

  clearEvent: () => null,
  updateApp: async () => Promise.resolve(initialAppState),
  setEvent: () => null,
});

export const useApp = () => useContext(AppContext);

interface AppProviderProps {
  children: React.ReactNode;
}

const sentryCaptureMessage = (message: string) =>
  Sentry.captureMessage(message, {
    user: {
      id: WebApp.initDataUnsafe?.user?.id,
    },
  });

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const readingConfigRef = useRef(false);
  const farmingTimerRef = useRef<NodeJS.Timer>();
  const calc = useRef(new Calculator());
  const [isBusy, setBusy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({} as User);
  const [app, setApp] = useState(initialAppState);
  const [progress, setProgress] = useState(50);
  const [farmingState, setFarmingState] = useState<FarmingState>('stopped');
  const [event, setEvent] = useState<Event>(null);
  const [level, setLevel] = useState(1);
  const [claimReward, setClaimReward] = useState<IClaimReward | null>(null);

  const [tasks, setTasks] = useState<ITask[]>([]);
  const [taskFactor, setTaskFactor] = useState(1);

  const [friendRewardBuffer, setFriendRewardBuffer] = useState(0);
  const [lastAppUpdate, setLastAppUpdate] = useState(0);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [friendsCount, setFriendsCount] = useState(0);

  useEffect(() => {
    const el = window.document.getElementById('app');

    if (el) {
      el.classList.remove('started');
      el.classList.remove('stopped');
      el.classList.remove('claimed');
      el.classList.remove('turbo');

      el.classList.add(farmingState);
    }
  }, [farmingState]);

  const clearEvent = async () => setEvent(null);

  const userUpgrade = async (type: UserUpgradeType) => {
    await postUserUpgrade(type);
    await farmingStart();

    const upgradeTypes = {
      turbo: 'insight',
      speed: 'physical_strength',
      fuel: 'mental_energy',
      luck: 'stress_resistance',
    };

    if (upgradeTypes[type]) {
      notificationEmit({
        title: t(`notifications.upgrade.${upgradeTypes[type]}_title`),
        subtitle: t(`notifications.upgrade.${upgradeTypes[type]}_subtitle`),
      });
    }

    setEvent({
      type,
    });
  };

  const farmingClaim = async () => {
    const data = await postUserClaim();

    console.log({ data });

    // Force start farming
    if (!data) await farmingStart();

    setClaimReward(data);

    setFarmingState('claimed');
    notificationEmit({
      title: t('notifications.farming.coins_collected_title'),
      subtitle: t('notifications.farming.coins_collected_subtitle'),
    });
  };

  const farmingTurboActivate = async () => {
    doHapticFeedback();

    await postUserTurbo();
    await farmingStart();

    notificationEmit({
      title: t('notifications.farming.insights_activated_title'),
      subtitle: t('notifications.farming.insights_activated_subtitle'),
    });
  };

  const farmingStart = async () => {
    clearInterval(farmingTimerRef.current);

    const app = await postStart(getInvitedBy());

    const { user, topFriends, tasks } = app;

    setApp(app);
    setUser(user);
    setTasks(tasks);
    const taskLevel = getTaskLevel(app);
    console.log({ taskLevel });
    setTaskFactor(taskLevel.factor);
    setFriendRewardBuffer(app.friendRewardBuffer);
    if (topFriends) setFriends(topFriends);
    setFriendsCount(app.state.friendsLevel1Count + app.state.friendsLevel2Count + app.state.friendsVirtualCount);

    // Init calculator
    const now = moment().unix();
    calc.current.debug = true;
    calc.current.StateManager = {
      // boost_logs: [],
      boostLogs: app.boostLogs ? app.boostLogs : [],
      game_config: {
        turbo_levels: app.config.turboLevels,
        speed_levels: app.config.speedLevels,
      },
      user_state: app.state,
    };
    calc.current.init();
    calc.current.round_started_at = moment.unix(app.state.lastClaimAt).unix();
    calc.current.round_finish_at = getRoundFinishAt(app);
    calc.current.upd_last_action_point(now);

    let turboCurrent = false;

    const updateRoundBalance = () => {
      if (isBusy) return;

      const now = moment().unix();
      const isRunning = now < calc.current.round_finish_at;
      const turboNew = calc.current.active_turbo_till > 0 && calc.current.active_turbo_till > now;

      // Turbo activated/deactivated
      if (turboCurrent !== turboNew) {
        const now = moment().unix();
        calc.current.upd_last_action_point(now);

        console.log('updLastActionPoint:', { turboCurrent, turboNew });
      }

      if (isRunning) {
        calc.current.upd_round_balance(now);
        setFarmingState(turboNew ? 'turbo' : 'started');
      } else {
        clearInterval(farmingTimerRef.current);

        calc.current.upd_round_balance(calc.current.round_finish_at);
        setFarmingState('stopped');
      }

      turboCurrent = turboNew;
    };

    updateRoundBalance();

    farmingTimerRef.current = setInterval(() => updateRoundBalance(), 1000);

    return app;
  };

  const startWithAttempts = async (attempt: number): Promise<boolean> => {
    await sleep(exponentialDelay(attempt));

    const app = await farmingStart();

    if (app === undefined) {
      sentryCaptureMessage(`startWithAttempts ${attempt} - main service failed'`);
      return false;
    }

    const { user, topFriends } = app;

    setApp(app);
    setUser(user);
    setFriendRewardBuffer(app.friendRewardBuffer);
    if (topFriends) setFriends(topFriends);
    setFriendsCount(user.friendsLevel1Count + user.friendsLevel2Count);

    return true;
  };

  const updateLevelProgress = () => {
    const level = getLevelForBalance(app.state.balance);
    setLevel(level);
    const nextLevelPrice = getPriceForLevel(level + 1);
    setProgress((app.state.balance / nextLevelPrice) * 100);
  };

  useEffect(() => {
    updateLevelProgress();
  }, [app.state.balance]);

  const setBusyDelayed = () => {
    console.log('delay ...');
    setTimeout(() => setBusy(false), 1500);
  };

  useEffect(() => {
    document.addEventListener('appHttpStart', () => setBusy(true));
    document.addEventListener('appApiError', setBusyDelayed);
    document.addEventListener('appHttpEnd', setBusyDelayed);
    document.addEventListener('appHttpError', (data: any) => {
      notificationEmit({
        title: t('notifications.http.unexpected_error_title'),
        subtitle: t('notifications.http.unexpected_error_subtitle'),
      });
      setBusyDelayed();
      console.log('appHttpError', { data });
    });

    const readConfig = async () => {
      readingConfigRef.current = true;

      // await deleteUser();

      console.debug({ i18n });
      console.debug({ initDataUnsafe: WebApp.initDataUnsafe });

      let isStarted = false;

      try {
        for (let x = 0; x < 5; x++) {
          if (!isStarted) isStarted = await startWithAttempts(x);
        }
      } catch (e: any) {
        if (e?.message === 'Authentication error: Invalid auth token') {
          sentryCaptureMessage(`Authentication error`);
          return;
        }
        throw e;
      }

      if (!isStarted) {
        metrikaEventAppCrashed();
        console.log('Worried Meerkats failed to start :(');
        sentryCaptureMessage('Worried Meerkats failed to start :(');
        return;
      }

      document.getElementById('preloader')?.remove();
      setLoading(false);
      metrikaEventAppStarted(0);
    };

    if (!readingConfigRef.current) {
      readConfig().then();
      return;
    }
    if (window.location.hostname !== 'localhost') sentryCaptureMessage('Double fire for useEffectOnce detected');
  }, []);

  const updateApp = async () => {
    setLastAppUpdate(Date.now);
    return farmingStart();
  };

  const updateUserTasks = async () => {
    const { tasks } = await getUserTasks();
    if (tasks) setTasks(tasks);
    return tasks;
  };

  return (
    <AppContext.Provider
      value={{
        claimReward,
        isBusy,
        loading,
        event,
        app,
        user,
        level,
        progress,
        farmingState,
        calculator: calc.current,
        tasks,
        taskFactor,
        lastAppUpdate,
        friendRewardBuffer,
        updateApp,
        friends,
        friendsCount,
        updateUserTasks,
        farmingClaim,
        farmingStart,
        userUpgrade,
        farmingTurboActivate,
        clearEvent,
        setEvent,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Row from '../../Row';
import FriendCard from './List/FriendCard';
import ClipboardButton from './ClipboardButton';
import { useApp } from '../../../contexts/AppContext';
import { InviteFriendButton } from './InviteFriendButton';
import Icon10 from '../../Icons/Icon10';
import Icon25 from '../../Icons/Icon25';

const Container = styled.div`
  margin: 20px auto 30px;
  width: 1000px;
`;

const FriendsListLabel = styled.div`
  color: white;
  font-size: 40px;
  font-weight: 600;
`;

const SubHeader = styled.div`
  color: #faff00;
  font-family: Roboto, sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 65px;
  text-align: center;
`;

const FriendsList: React.FC = () => {
  const { friends, friendsCount } = useApp();
  const { t } = useTranslation();

  return (
    <Container>
      {!!friendsCount && (
        <>
          <SubHeader style={{ marginBottom: '10px' }}>
            <Icon10 />
            <span style={{ width: '170px', display: 'inline-block' }} />
            L1 {t('friends.and')} L2
            <Icon25 />
            <span style={{ width: '170px', display: 'inline-block' }} />
          </SubHeader>
          <Row gap="36px" margin="0 0 20px">
            <FriendsListLabel>
              {t('friends.listOfYourFriends')} ({friendsCount})
            </FriendsListLabel>
          </Row>
        </>
      )}
      {!friendsCount && (
        <>
          <SubHeader>
            {t('friends.yourReward1')}
            <Icon10 />
            <span style={{ width: '170px', display: 'inline-block' }} />
            {t('friends.yourReward2')}
            <br />
            {t('friends.yourReward3')}
            <Icon25 />
            <span style={{ width: '170px', display: 'inline-block' }} />
            <br />
            {t('friends.yourReward4')}
          </SubHeader>
          <img alt="friends" src="/img/friends/friends.png" style={{ margin: '60px auto', display: 'block' }} />
        </>
      )}
      {!!friendsCount && (
        <div style={{ maxHeight: '720px', overflow: 'scroll' }}>
          {friends.map((friend) => (
            <FriendCard key={friend.id} friend={friend} />
          ))}
        </div>
      )}
      <Row margin="30px 0 37px" gap="40px">
        <InviteFriendButton />
        <ClipboardButton />
      </Row>
    </Container>
  );
};

export default FriendsList;

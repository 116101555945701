import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-use';
import WebApp from '@twa-dev/sdk';
import { QueryClient, QueryClientProvider } from 'react-query';
import TonConnect from '@tonconnect/sdk';

import { env } from '../lib/env';
import { handleLanguageChange, handleResize, shouldBlockDesktop } from '../lib/utils';

// Components
import { AppProvider, useApp } from '../contexts/AppContext';
import Popups from '../components/Popups';
import { Notifications } from '../components/Notifications';
import { onlineStatusInit } from '../controllers/OnlineStatusController';
import ImagePreloader from '../components/ImagePreloader';
import BlockDesktop from '../components/BlockDesktop';

// Pages
import Friends from '../pages/Friends';
import Home from '../pages/Home';
import Tasks from '../pages/Tasks';
import Boosts from '../pages/Boosts';
import Statistics from '../pages/Statistics';
import Leaderboard from '../pages/Leaderboard';
import BoostSpeed from '../pages/boosts/BoostSpeed';
import BoostCalm from '../pages/boosts/BoostCalm';
import BoostCloud from '../pages/boosts/BoostCloud';
import Menu from '../components/Menu';
import Task from '../pages/Task';
import Settings from '../pages/Settings';
import Diary from '../pages/Diary';
import BoostLuck from '../pages/boosts/BoostLuck';
import Welcome from '../pages/Welcome';
import { useTranslation } from 'react-i18next';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Airdrop from '../pages/Airdrop';
import FriendsSpirit from '../pages/FriendsSpirit';
import Flocks from '../pages/Flocks';
import Flock from '../pages/Flock';

const ScaledContainer = styled.div`
  width: calc(100% / var(--scale));
  height: 0;
  transform-origin: left 0;
  transform: scale(var(--scale));
  display: flex;
  flex-direction: row;
  background: black;
`;

const AppContainer = styled.div`
  @keyframes slideleft {
    from {
      background-position: 0%;
    }
    to {
      background-position: 90000%;
    }
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  width: 1080px;
  height: calc(100vh / var(--scale));
  margin: 0 auto;

  background: url(/img/bg_full_cave_looping.webp) repeat-x center center;
  background-size: cover;
  animation: slideleft 240000s infinite linear;
  animation-play-state: 'running';

  .fade-enter {
    position: absolute;
    opacity: 0;
    z-index: 10;
    transition: opacity 300ms ease-in;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(50% - 540px);
    height: 100%;
    background-color: black;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }

  &.running {
    animation: slideleft 240000s infinite linear;
    animation-play-state: running;
  }
  &.turbo {
    animation: slideleft 40000s infinite linear;
    animation-play-state: running;
  }
  &.claimed {
    animation-play-state: paused;
  }
  &.stopped {
    animation-play-state: paused;
  }
`;

Sentry.init({
  dsn: env.sentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.05, //  Capture 100% of the transactions
  tracePropagationTargets: ['localhost'],
  release: env.build || 'unknown',
});

const queryClient = new QueryClient();
const connector = new TonConnect({
  manifestUrl: 'https://cdn2.timesoul.com/tonconnect-manifest.json',
  walletsListSource: '/wallets-v2.json',
});

const Providers = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>
    <TonConnectUIProvider connector={connector}>
      <AppProvider>{children}</AppProvider>
    </TonConnectUIProvider>
  </QueryClientProvider>
);

const HideWhileLoading = ({ children }: { children: React.ReactNode }) => {
  const { loading } = useApp();

  if (loading) return null;
  return children;
};

const PreloadImages = () => {
  const { app } = useApp();
  const flocks = app.flocks ? [...app.flocks.flocksTop, ...app.flocks.flocksRecent] : [];
  const flocksImages = [...new Set(flocks.map((item) => item?.coverUrl).filter(Boolean) as string[])];

  const images = [
    '/img/userLeaderboard.png',

    '/img/friends/friends.png',
    '/img/friends/spirit_x1.png',
    '/img/friends/spirit_x5.png',
    '/img/friends/spirit_x9.png',

    '/img/Meerkat.png',
    '/img/MeerkatHeadCloud.png',
    '/img/MeerkatHeadLotos.png',
    '/img/MeerkatHeadTime.png',
    '/img/MeerkatRun.png',
    '/img/MeerkatStats.png',
    '/img/MeerkatSunglasses.png',

    ...flocksImages,
    ...Array.from({ length: 4 }, (_, i) => `/img/animation/cloud${i + 1}.webp`),
    ...Array.from({ length: 6 }, (_, i) => `/img/animation/walk${i + 1}.webp`),
    ...Array.from({ length: 17 }, (_, i) => `/img/diary/page${i + 1}.webp`),

    'https://dapp.timesoul.com/assets/cover-12a1fbb5.webp',
    'https://dapp.timesoul.com/assets/bg1-a407c28f.webp',
    '/img/diary/bg.webp',
    '/img/diary/staples.webp',
  ];

  return <ImagePreloader images={[...images]} />;
};

export function App() {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    handleResize();
    onlineStatusInit(t);
  }, []);

  useEffect(() => {
    handleLanguageChange(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const el = window.document.getElementById('app');

    if (el) {
      if (window.location.pathname === '/') {
        el.classList.remove('stopped');
        el.classList.add('running');
      } else {
        el.classList.remove('running');
        el.classList.add('stopped');
      }
    }
  }, [window.location.pathname]);

  if (shouldBlockDesktop()) {
    document.getElementById('preloader')?.remove();
    return <BlockDesktop />;
  } else {
    WebApp.BackButton.show();
  }

  const HandleBack = () => {
    const location = useLocation();
    const navigate = useNavigate();

    WebApp.BackButton.onClick(() => {
      navigate(-1);
    });

    if (location.pathname === '/') WebApp.BackButton.hide();
    else WebApp.BackButton.show();

    return null;
  };

  return (
    <Providers>
      <Router>
        <Notifications />
        <PreloadImages />
        <HandleBack />
        <HideWhileLoading>
          <Menu />
          <Popups />
          <ScaledContainer>
            <AppContainer id="app">
              <Routes location={location}>
                {/* Main menu */}
                <Route path="/airdrop" element={<Airdrop />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/" element={<Home />} />
                <Route path="/boosts" element={<Boosts />} />
                <Route path="/boost/calm" element={<BoostCalm />} />
                <Route path="/boost/speed" element={<BoostSpeed />} />
                <Route path="/boost/cloud" element={<BoostCloud />} />
                <Route path="/boost/luck" element={<BoostLuck />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/task/:id" element={<Task />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/friends/spirit" element={<FriendsSpirit />} />

                {/* Top menu */}
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/flocks" element={<Flocks />} />
                <Route path="/flock/:referer/:id" element={<Flock />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/diary" element={<Diary />} />
                <Route path="/language" element={<Settings selection="language" />} />
              </Routes>
            </AppContainer>
          </ScaledContainer>
        </HideWhileLoading>
      </Router>
    </Providers>
  );
}

export default App;

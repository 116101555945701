import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import Progress from '../components/pages/Leaderboard/Progress';
import Row from '../components/Row';
import styled from 'styled-components';
import LeaderCard from '../components/pages/Leaderboard/LeaderCard';
import { compactAmount, doHapticFeedback, getLevelName } from '../lib/utils';
import { NextButton, PrevButton } from '../components/DirectionButtons';
import { useTranslation } from 'react-i18next';
import { useLeaderboard } from '../lib/api';
import Loading from '../components/Loading';
import { Leader } from '../lib/types';
import { getPriceForLevel } from '../lib/leaderboard';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { NavLink } from 'react-router-dom';
import { LeaderboardTypeButton } from '../components/pages/Flocks/Buttons';

const Level = styled.div`
  margin: 0 auto;
  font-size: 81px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Balance = styled.div`
  color: #cdcdcd;
  margin: 0 auto;
  font-size: 45px;
  font-weight: 500;
`;

const BeTheFirst = styled.div`
  margin: 0 auto;
  font-size: 100px;
  font-family: var(--font), sans-serif;
  text-transform: uppercase;
`;

type LeaderWithPlace = Leader & { place?: number };

const Leaderboard: React.FC = () => {
  const { level, progress, user, app } = useApp();
  const [observedLevel, setObservedLevel] = useState(level);
  const { t } = useTranslation();

  const nextLevelPrice = getPriceForLevel(level + 1);
  const observedLevelPrice = getPriceForLevel(observedLevel);
  const observedNextLevelPrice = getPriceForLevel(observedLevel + 1);
  const { data, isLoading } = useLeaderboard(observedLevelPrice, observedNextLevelPrice - 1);

  const handlePrev = async () => {
    if (observedLevel > 1) setObservedLevel((prevObservedLevel) => prevObservedLevel - 1);
  };

  const handleNext = async () => {
    setObservedLevel((prevObservedLevel) => prevObservedLevel + 1);
  };

  const adjustLeaderboard = (leaderboard: Leader[]): LeaderWithPlace[] => {
    if (isLoading || !leaderboard) return [];

    let adjustedLeaderboard = [...leaderboard];

    // Remove user from lower level leaderboards
    if (observedLevel < level) {
      adjustedLeaderboard = adjustedLeaderboard.filter((leader) => leader.id !== user.id);
    }

    // Add user to current level leaderboard if missing
    if (observedLevel === level && !adjustedLeaderboard.find((leader) => leader.id === user.id)) {
      const userEntry: LeaderWithPlace = {
        id: user.id,
        displayAs: user.displayAs,
        balance: app.state.balance,
      };
      adjustedLeaderboard.push(userEntry);
    }

    // Sort the leaderboard by balance in descending order
    adjustedLeaderboard.sort((a, b) => b.balance - a.balance);

    return adjustedLeaderboard.map((leader, index) => {
      const isLastPlace = index === adjustedLeaderboard.length - 1 && adjustedLeaderboard.length > 1;
      const shouldHaveUndefinedPlace = isLastPlace && leader.id === user.id && observedLevel === level;

      return {
        ...leader,
        place: shouldHaveUndefinedPlace ? undefined : index + 1,
      };
    });
  };

  const patchedLeaderboard = adjustLeaderboard(data?.leaderboard || []);

  const leaderBoardIsEmpty = !isLoading && !patchedLeaderboard.length;

  const prevBlocked = observedLevel === 1;
  const nextBlocked = leaderBoardIsEmpty && observedLevel > level;

  useEffectOnce(() => {
    doHapticFeedback();
  });

  return (
    <PageContainer hcenter={false}>
      <Row margin={'-20px 0 20px'} gap="20px">
        <LeaderboardTypeButton disabled>{t('flocks.single')}</LeaderboardTypeButton>
        <NavLink to="/flocks">
          <LeaderboardTypeButton>{t('flocks.flocks')}</LeaderboardTypeButton>
        </NavLink>
      </Row>
      <Row margin={'0 0 20px'} gap="40px">
        <PrevButton onClick={handlePrev} disabled={prevBlocked} />
        <img alt="leader" src="/img/userLeaderboard.png" height="330" />
        <NextButton onClick={handleNext} disabled={isLoading || nextBlocked} />
      </Row>
      <Row>
        <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <Level>{getLevelName(observedLevel, t)}</Level>
        </motion.div>
      </Row>
      <Row>
        <motion.div initial={{ x: -64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
          <Balance>
            {observedLevel === level && `${compactAmount(app.state.balance)} / ${compactAmount(nextLevelPrice)}`}
            {observedLevel !== level &&
              `${compactAmount(observedLevelPrice)} - ${compactAmount(observedNextLevelPrice)}`}
          </Balance>
        </motion.div>
      </Row>
      <Row margin={'26px 48px 38px'}>{observedLevel === level && <Progress progress={progress} />}</Row>
      {isLoading && <Loading fontSize="100px" />}
      {leaderBoardIsEmpty && <BeTheFirst>{observedLevel > level ? t('leaderboard.beTheFirst') : '...'}</BeTheFirst>}
      {!isLoading && (
        <div style={{ maxHeight: '729px', overflow: 'scroll' }}>
          {patchedLeaderboard.map((leader) => (
            <LeaderCard key={leader.id} leader={leader} place={leader.place} length={patchedLeaderboard.length} />
          ))}
        </div>
      )}
    </PageContainer>
  );
};

export default Leaderboard;

import { beginCell } from '@ton/ton';
import { Address } from '@ton/core';

import { env } from './env';

const { wallet } = env;

export const TON_NANO = 1000000000;
export const DEPOSIT_NEEDED = 500000000;

export const addressToShort = (address: string) => {
  const count = address.length;
  return `${address.slice(0, 6)}...${address.slice(count - 6, count)}`;
};

export const queryAmountFromNetwork = async (tonAddress: string, userId: number): Promise<number> => {
  const response = await fetch(
    `https://toncenter.com/api/v3/transactions?account=${tonAddress}&limit=1000&offset=0&sort=desc`
  );
  const json = await response.json();
  const txs: any[] = json.transactions;
  const toAddressRaw = Address.parseFriendly(wallet).address.toRawString().toLowerCase();

  const txComment = txs.filter(
    (tx) =>
      tx.out_msgs[0]?.message_content.decoded?.comment === `MC_${userId}` &&
      // tx.out_msgs[0]?.destination.toLowerCase() === '0:1E31B10FFC496F5AA311F75EB1CC685D891DC153A0B57563AAA63582CD5A23DC'
      tx.out_msgs[0]?.destination.toLowerCase() === toAddressRaw
  );
  const amounts = txComment.map((tx) => tx.out_msgs[0]?.value);
  const total = amounts.reduce((acc, cur) => acc + Number(cur), 0);
  const amountTon = total ? total / TON_NANO : 0;

  console.log('queryAmountFromNetwork:', { txComment, amounts, total, wallet, toAddressRaw, amountTon });

  return amountTon;
};

export const txPrepare = (userId: number) => {
  const body = beginCell()
    .storeUint(0, 32) // Write 32 zero bits to indicate a text comment will follow
    .storeStringTail(`MC_${userId}`) // Write the text comment
    .endCell();

  const tx = {
    validUntil: Math.floor(Date.now() / 1000) + 360 * 6,
    messages: [
      {
        address: wallet, // Destination address
        amount: Number(DEPOSIT_NEEDED).toFixed(), // Toncoin in nanotons
        payload: body.toBoc().toString('base64'),
      },
    ],
  };

  return tx;
};

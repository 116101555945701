import { IconDone } from '../../Icons/IconDone';
import { useApp } from '../../../contexts/AppContext';
import { ILevel } from 'src/lib/types';
import { useTranslation } from 'react-i18next';

const BoostCondtions = ({ levelNext }: { levelNext: ILevel }) => {
  const { t } = useTranslation();
  const { app, friendsCount } = useApp();

  return (
    <>
      {levelNext.taskCount && (
        <p className="tasks">
          {t('boosts.conditions.needSolvedTasks')} {levelNext.taskCount}{' '}
          {app.state.tasksCount >= levelNext.taskCount && <IconDone />}
        </p>
      )}
      {levelNext.friendCount && (
        <p className="tasks">
          {t('boosts.conditions.needFriends')} {levelNext.friendCount}{' '}
          {friendsCount >= levelNext.friendCount && <IconDone />}
        </p>
      )}
      {levelNext.claimCount && (
        <p className="tasks">
          {t('boosts.conditions.needClaims')} {levelNext.claimCount}{' '}
          {app.state.claimsCount >= levelNext.claimCount && <IconDone />}
        </p>
      )}
    </>
  );
};

export default BoostCondtions;

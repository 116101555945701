export const IconSurprise = () => (
  <svg width="194" height="167" viewBox="0 0 194 167" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M158.612 23.9745L121.059 45.6607C119.508 46.5562 118.977 48.5394 119.872 50.0905L120.415 51.0312C121.31 52.5823 123.293 53.1137 124.844 52.2182L162.397 30.532C163.947 29.6365 164.479 27.6532 163.583 26.1022L163.04 25.1614C162.145 23.6104 160.162 23.079 158.612 23.9745Z"
      fill="#B48EC0"
    />
    <path
      d="M123.236 54.519C122.798 54.519 122.344 54.4541 121.907 54.3406C120.594 53.9839 119.491 53.1408 118.81 51.9734L118.259 51.0168C117.579 49.8494 117.4 48.4712 117.757 47.1578C118.113 45.8445 118.956 44.742 120.124 44.061L157.683 22.3667C158.85 21.6857 160.228 21.5073 161.541 21.864C162.854 22.2207 163.956 23.0639 164.637 24.2313L165.188 25.1879C165.869 26.3553 166.047 27.7335 165.69 29.0468C165.334 30.3601 164.491 31.4627 163.324 32.1437L125.765 53.838C124.987 54.292 124.111 54.519 123.236 54.519ZM160.211 25.3987C159.968 25.3987 159.741 25.4635 159.531 25.577L121.971 47.2713C121.647 47.4497 121.42 47.7578 121.323 48.1145C121.226 48.4712 121.274 48.8441 121.453 49.1684L122.004 50.125C122.182 50.4493 122.49 50.6763 122.847 50.7736C123.203 50.8708 123.576 50.8222 123.901 50.6438L161.46 28.9495C161.784 28.7712 162.011 28.4631 162.108 28.1064C162.205 27.7497 162.157 27.3768 161.978 27.0525L161.427 26.0959C161.249 25.7716 160.941 25.5446 160.584 25.4473C160.471 25.4149 160.341 25.3987 160.211 25.3987Z"
      fill="#303030"
    />
    <path
      d="M64.1018 30.0193L101.655 51.7055C103.205 52.601 105.188 52.0696 106.083 50.5186L106.626 49.5778C107.522 48.0268 106.99 46.0435 105.44 45.148L67.8869 23.4618C66.3362 22.5663 64.3534 23.0978 63.4582 24.6488L62.9151 25.5896C62.0199 27.1406 62.5511 29.1238 64.1018 30.0193Z"
      fill="#B48EC0"
    />
    <path
      d="M103.265 53.9993C102.39 53.9993 101.514 53.7723 100.736 53.3183L63.1771 31.624C62.01 30.943 61.167 29.8567 60.8104 28.5272C60.4538 27.2138 60.6321 25.8356 61.3129 24.6682L61.8641 23.7116C62.5449 22.5442 63.631 21.7011 64.9602 21.3444C66.2732 20.9877 67.6511 21.166 68.8182 21.847L106.377 43.5413C107.544 44.2223 108.387 45.3086 108.744 46.6382C109.101 47.9515 108.922 49.3297 108.242 50.4971L107.69 51.4537C107.01 52.6211 105.923 53.4643 104.594 53.821C104.157 53.9345 103.703 53.9993 103.265 53.9993ZM66.2732 24.879C66.1436 24.879 66.0301 24.8952 65.9004 24.9277C65.5438 25.0249 65.2358 25.2519 65.0575 25.5762L64.5063 26.5328C64.328 26.8571 64.2794 27.2138 64.3766 27.5868C64.4739 27.9435 64.7008 28.2515 65.0251 28.4299L102.584 50.1242C102.908 50.3025 103.265 50.3512 103.638 50.2539C103.994 50.1566 104.302 49.9296 104.481 49.6053L105.032 48.6487C105.21 48.3244 105.259 47.9677 105.162 47.5948C105.064 47.2381 104.837 46.93 104.513 46.7517L66.9541 25.0574C66.7433 24.9439 66.5164 24.879 66.2732 24.879Z"
      fill="#303030"
    />
    <path
      d="M139.851 13.1888C138.295 11.8431 136.334 11.1296 134.161 11.1296C128.666 11.1296 122.02 15.6858 116.8 23.0307C109.311 33.5536 107.933 45.179 113.591 50.0918C115.147 51.4376 117.108 52.151 119.281 52.151C124.776 52.151 131.422 47.5949 136.642 40.25C144.131 29.7271 145.509 18.1017 139.851 13.1888ZM131.795 36.0505C127.386 42.2443 121.534 45.2925 118.729 42.8604C115.925 40.4283 117.222 33.4239 121.631 27.2301C126.04 21.0364 131.892 17.9882 134.696 20.4203C137.501 22.8523 136.204 29.8568 131.795 36.0505Z"
      fill="#B48EC0"
    />
    <path
      d="M119.281 54.0164C116.671 54.0164 114.288 53.1408 112.391 51.5032C105.907 45.8769 107.139 33.457 115.309 21.9613C120.869 14.1462 128.099 9.28198 134.178 9.28198C136.788 9.28198 139.171 10.1575 141.067 11.7951C147.551 17.4214 146.319 29.8413 138.149 41.3208C132.589 49.136 125.359 54.0001 119.281 54.0001V54.0164ZM134.178 12.9788C129.347 12.9788 123.122 17.3403 118.324 24.1016C111.516 33.684 109.976 44.4825 114.823 48.6982C116.039 49.7521 117.579 50.3034 119.297 50.3034C124.127 50.3034 130.352 45.9418 135.15 39.1806C141.959 29.5981 143.499 18.7996 138.652 14.584C137.436 13.53 135.896 12.9788 134.178 12.9788ZM121.161 45.5527C119.767 45.5527 118.519 45.1149 117.546 44.2555C113.931 41.11 115.05 33.3273 120.156 26.1607C123.528 21.4263 127.856 18.2159 131.471 17.7943C133.205 17.5835 134.761 18.0213 135.929 19.0266C139.543 22.1721 138.425 29.9548 133.319 37.1214C129.947 41.8559 125.619 45.0662 122.004 45.4878C121.712 45.5202 121.437 45.5365 121.161 45.5365V45.5527ZM132.314 21.4425C132.184 21.4425 132.054 21.4425 131.908 21.4749C129.801 21.7181 126.283 23.9394 123.171 28.3172C118.827 34.4137 118.389 40.0886 119.978 41.4667C120.107 41.5802 120.529 41.9532 121.582 41.8234C123.69 41.5802 127.207 39.3589 130.32 34.9811C134.664 28.8847 135.102 23.2098 133.513 21.8316C133.4 21.7343 133.076 21.4425 132.33 21.4425H132.314Z"
      fill="#303030"
    />
    <path
      d="M90.5075 40.2656C95.7272 47.6105 102.39 52.1666 107.869 52.1666C110.025 52.1666 112.002 51.4532 113.558 50.1075C119.216 45.1946 117.838 33.5692 110.349 23.0463C105.129 15.7014 98.4667 11.1453 92.9876 11.1453C90.8317 11.1453 88.854 11.8587 87.2978 13.2044C81.6405 18.1173 83.0183 29.7427 90.5075 40.2656ZM92.4527 20.4359C95.2571 18.0038 101.109 21.052 105.518 27.2457C109.927 33.4395 111.224 40.4277 108.42 42.876C105.615 45.3081 99.7635 42.2599 95.3543 36.0662C90.9451 29.8724 89.6483 22.8842 92.4527 20.4359Z"
      fill="#B48EC0"
    />
    <path
      d="M107.869 54.0158C101.79 54.0158 94.56 49.1516 88.9999 41.3364C80.8299 29.8569 79.5979 17.437 86.082 11.8108C87.9786 10.1569 90.3615 9.29761 92.9714 9.29761C99.0502 9.29761 106.28 14.1618 111.84 21.9769C120.01 33.4564 121.242 45.8764 114.758 51.5188C112.861 53.1564 110.478 54.032 107.869 54.032V54.0158ZM92.015 39.1962C96.8294 45.9574 103.054 50.319 107.869 50.319C109.587 50.319 111.127 49.7677 112.343 48.7138C117.189 44.4982 115.649 33.6997 108.841 24.1172C104.027 17.356 97.802 12.9944 92.9876 12.9944C91.2693 12.9944 89.7293 13.5457 88.5136 14.5996C83.6667 18.8152 85.2067 29.6137 92.015 39.1962ZM106.004 45.5683C105.729 45.5683 105.453 45.5521 105.161 45.5196C101.547 45.0819 97.2185 41.8715 93.8467 37.1532C88.7405 29.9704 87.622 22.2039 91.2369 19.0584C92.404 18.0369 93.9602 17.6154 95.6947 17.8262C99.3096 18.2639 103.638 21.4743 107.009 26.1926C112.116 33.3754 113.234 41.1419 109.619 44.2874C108.63 45.1467 107.398 45.5845 106.004 45.5845V45.5683ZM93.6684 21.8472C92.0798 23.2254 92.5175 28.9003 96.8618 34.9968C99.9742 39.3745 103.492 41.5959 105.599 41.8391C106.637 41.9526 107.058 41.5959 107.204 41.4824C108.793 40.1042 108.339 34.4293 104.011 28.3328C100.898 23.955 97.3806 21.7337 95.2732 21.4905C94.2358 21.3608 93.8143 21.7337 93.6684 21.8472Z"
      fill="#303030"
    />
    <path
      d="M168.722 64.4421H60.0973C52.5143 64.4421 46.3672 70.5907 46.3672 78.1754V150.928C46.3672 158.512 52.5143 164.661 60.0973 164.661H168.722C176.305 164.661 182.452 158.512 182.452 150.928V78.1754C182.452 70.5907 176.305 64.4421 168.722 64.4421Z"
      fill="#B48EC0"
    />
    <path
      d="M168.722 166.282H60.0972C51.6354 166.282 44.7461 159.392 44.7461 150.928V78.1757C44.7461 69.712 51.6354 62.821 60.0972 62.821H168.722C177.184 62.821 184.073 69.712 184.073 78.1757V150.928C184.073 159.392 177.184 166.282 168.722 166.282ZM60.0972 66.0638C53.4186 66.0638 47.9881 71.4955 47.9881 78.1757V150.928C47.9881 157.608 53.4186 163.04 60.0972 163.04H168.722C175.401 163.04 180.831 157.608 180.831 150.928V78.1757C180.831 71.4955 175.401 66.0638 168.722 66.0638H60.0972Z"
      fill="#303030"
    />
    <path
      d="M182.938 48.6003H44.0814C38.862 48.6003 34.6309 52.8325 34.6309 58.0531V80.0717C34.6309 85.2923 38.862 89.5244 44.0814 89.5244H182.938C188.158 89.5244 192.389 85.2923 192.389 80.0717V58.0531C192.389 52.8325 188.158 48.6003 182.938 48.6003Z"
      fill="#B48EC0"
    />
    <path
      d="M182.938 91.1461H44.0814C37.9863 91.1461 33.0098 86.1847 33.0098 80.072V58.0534C33.0098 51.9569 37.9701 46.9792 44.0814 46.9792H182.938C189.033 46.9792 194.01 51.9407 194.01 58.0534V80.072C194.01 86.1684 189.049 91.1461 182.938 91.1461ZM44.0814 50.222C39.7694 50.222 36.2518 53.7405 36.2518 58.0534V80.072C36.2518 84.3849 39.7532 87.9033 44.0814 87.9033H182.938C187.25 87.9033 190.768 84.3849 190.768 80.072V58.0534C190.768 53.7405 187.25 50.222 182.938 50.222H44.0814Z"
      fill="#303030"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.33">
      <path
        d="M171.786 91.1131V151.397C129.72 151.283 87.6708 151.17 45.6052 151.056C48.4096 154.996 51.1978 158.936 54.0021 162.86C91.9016 163.022 129.801 163.184 167.701 163.346C169.095 163.33 172.402 163.087 175.563 160.833C180.912 157.023 181.269 150.7 181.317 149.84V89.5242L171.786 91.1131Z"
        fill="#A579B2"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.33">
      <path
        d="M180.166 49.9961L180.474 79.4732C131.698 79.1327 82.9372 78.7922 34.1606 78.4355C35.6034 81.7593 37.0461 85.067 38.4726 88.3909H181.382C182.306 88.3909 184.721 88.2774 187.137 86.6884C189.892 84.8886 190.865 82.343 191.157 81.5323V58.4112C191.059 57.4546 190.622 54.2604 187.898 51.7635C186.423 50.4177 184.867 49.7854 183.878 49.4773C182.646 49.6556 181.398 49.8178 180.166 49.9961Z"
        fill="#A579B2"
      />
    </g>
    <path d="M127.062 48.6179H100.979V164.37H127.062V48.6179Z" fill="#FAFF00" />
    <path
      d="M128.699 165.991H99.3745V47.0125H128.699V165.991ZM102.617 162.764H125.457V50.239H102.617V162.764Z"
      fill="#303030"
    />
    <path
      d="M107.22 71.7703H107.204C106.47 71.7703 105.875 72.3655 105.875 73.0998V141.863C105.875 142.598 106.47 143.193 107.204 143.193H107.22C107.954 143.193 108.549 142.598 108.549 141.863V73.0998C108.549 72.3655 107.954 71.7703 107.22 71.7703Z"
      fill="white"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M119.864 50.0926V164.498H125.359V48.6333C125.359 48.6333 120.302 50.0926 119.864 50.0926Z"
        fill="#EDD500"
      />
    </g>
    <g filter="url(#filter0_d_6_65)">
      <path
        d="M5.68198 71.9986C2.80189 67.7453 3.9277 61.9712 8.19657 59.1016L92.9281 2.14435C97.1969 -0.725216 102.992 0.396486 105.872 4.64974L124.923 32.7828C127.803 37.036 126.677 42.8102 122.408 45.6798L37.6767 102.637C33.4078 105.507 27.6124 104.385 24.7323 100.132L5.68198 71.9986Z"
        fill="#C12525"
      />
    </g>
    <path
      d="M58.8309 79.0075L57.3138 80.0433L28.7597 75.9513L39.583 92.1496L36.3592 94.3507L16.9221 65.2609L18.4865 64.1927L46.9138 68.3015L36.1226 52.1512L39.3937 49.9177L58.8309 79.0075Z"
      fill="white"
    />
    <path
      d="M78.1721 65.8017L62.1006 76.775L42.6635 47.6852L58.735 36.7119L60.8484 39.8749L51.2245 46.4459L57.7248 56.1745L64.125 51.8046L66.3025 55.0634L59.9023 59.4333L66.4027 69.1618L76.0267 62.5908L78.1721 65.8017Z"
      fill="white"
    />
    <path
      d="M106.414 30.4676C108.485 33.5667 109.176 36.9094 108.489 40.4959C107.781 44.0504 105.893 46.8742 102.828 48.9675L94.5785 54.5998L93.906 53.5934C93.1217 55.1059 91.8287 56.4772 90.0272 57.7073L81.7307 63.372L62.2936 34.2821L68.7412 29.8799L86.0328 55.7588L89.2092 53.59C90.0942 52.9858 90.6392 52.1484 90.8444 51.0778C91.0496 50.0072 90.8533 49.0246 90.2555 48.1301L75.1413 25.51L81.4941 21.1724L98.7858 47.0514L102.057 44.8178C102.942 44.2136 103.471 43.387 103.645 42.338C103.818 41.289 103.606 40.3172 103.008 39.4226L87.8943 16.8025L94.3418 12.4003L106.414 30.4676Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_6_65"
        x="0.0863647"
        y="0.554565"
        width="130.432"
        height="111.672"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_65" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6_65" result="shape" />
      </filter>
    </defs>
  </svg>
);
